<template>
  <table
    :data-alternate="alternate"
    class="grid gap-y-1 table-columns group/table"
  >
    <thead class="contents">
      <tr class="contents bg-white">
        <th
          v-for="(header, i) of headers"
          :key="i"
          scope="col"
          class="hidden first:flex data-[main=true]:flex landscape:flex mb-2 py-2 px-1 first:pl-4 last:pr-4 sticky top-0 first:left-0 first:z-20 text-gray-500 text-sm text-bold border-b border-gray-200 bg-inherit justify-start data-[align=center]:justify-center data-[align=right]:justify-end"
          :data-align="header.align"
          :data-main="header.main"
        >
          {{ header.text }}
        </th>
      </tr>
    </thead>
    <DatatableRows
      :headers="headers"
      :elements="filtered"
      :group-by="groupBy"
      :selection="selection"
      :selectable="selectable"
      class="contents"
      @select="select"
    >
      <template
        v-for="header of headers"
        #[header.key]="itemProps"
      >
        <slot
          :name="header.key"
          v-bind="itemProps"
        />
      </template>
    </DatatableRows>
    <slot name="append" />
    <tfoot
      v-if="!noFooter"
      class="contents"
    >
      <DatatableFooter
        :headers="headers"
        :elements="filtered"
        :group-by="groupBy"
        :row-label="footerLabel"
      />
    </tfoot>
  </table>
</template>
<script setup lang="ts" generic="TableElement extends Record<string, any>">
interface Props {
  headers: TableHeader<TableElement>[]
  elements?: TableElement[]
  groupBy?: TableGroup<TableElement>[]
  noFooter?: boolean
  search?: string
  selectable?: boolean
  alternate?: boolean
  footerLabel?: string
  gridColumns?: 'firstLargest' | 'lastSmallest'
}
const props = withDefaults(defineProps<Props>(), {
  elements: () => ([] as TableElement[]),
  groupBy: () => ([
    { key: 'type' },
    { key: 'category' },
    { key: 'name' }
  ] as TableGroup<TableElement>[]),
  noFooter: false,
  search: undefined,
  selectable: true,
  alternate: false,
  footerLabel: 'TOTAL',
  gridColumns: 'firstLargest'
})


const emit = defineEmits<{
  select: [val?: TableElement]
}>()

const selection = ref<TableElement>()

const landscapeStyle = computed(() => {
  const landscapeHeaders = props.headers.length
  if (props.gridColumns === 'firstLargest') {
    if (landscapeHeaders === 1) {
      return '2fr'
    }
    return `2fr repeat(${landscapeHeaders - 1}, minmax(min-content, 1fr))`
  } else {
    if (landscapeHeaders === 1) {
      return '1fr'
    } else {
      return `repeat(${landscapeHeaders - 1}, auto) min-content`
    }
  }
})

const portraitStyle = computed(() => {
  if (props.headers.length === 1) {
    return '2fr'
  }
  const portraitHeaders = props.headers.filter(({ main }) => main).length
  return `2fr repeat(${portraitHeaders}, minmax(min-content, 1fr))`
})

const filtered = computed(() => {
  if (!props.search) {
    return props.elements
  }
  const searchableKeys = props.headers.filter(header => header.searchable).map(({ key }) => key)
  if (searchableKeys.length === 0) {
    return props.elements
  }
  const filtered = props.elements.filter(element => {
    for (const key of searchableKeys) {
      if (element[key].toString().toLowerCase().includes(props.search!.toLowerCase().trim())) {
        return true
      }
    }
    return false
  })
  return filtered
})

function select(element?: TableElement) {
  selection.value = element
  emit('select', element)
}

</script>
<style scoped>
@media (orientation:landscape) {
  .table-columns {
    grid-template-columns: v-bind(landscapeStyle);
  }
}
@media (orientation: portrait) {
  .table-columns {
    grid-template-columns: v-bind(portraitStyle);
  }
}
</style>
