<template>
  <tr
    class="contents text-sm text-black font-semibold group/headers bg-white"
    data-level="0"
  >
    <td
      v-for="(header, i) of headers"
      :key="i"
      class="hidden first:flex data-[main=true]:flex landscape:flex bg-inherit items-center border-t border-b border-gray-200 mt-2 py-2 px-1 first:pl-4 last:pr-4 sticky first:left-0 first:z-20 justify-start data-[align=right]:justify-end data-[align=center]:justify-center [font-variant-numeric:tabular-nums] tracking-tight first:[font-variant-numeric:unset] first:tracking-normal group/header"
      :data-align="header.align"
      :data-main="header.main"
    >
      <slot :name="header.key">
        {{ filters.format(sums[header.key], header.format) }}
      </slot>
    </td>
  </tr>
</template>
<script setup lang="ts" generic="TableElement extends Record<string, any>">
interface Props {
  headers: TableHeader<TableElement>[]
  elements: TableElement[]
  groupBy: TableGroup<TableElement>[]
  level?: number
  rowLabel?: string
}
const props = withDefaults(defineProps<Props>(), {
  level: 0,
  rowLabel: 'TOTAL'
})

const sums = useSums(props)
</script>
