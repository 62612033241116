<template>
  <tbody>
    <tr
      class="contents data-[level='0']:hidden cursor-default data-[expandable=true]:cursor-pointer data-[selectable=true]:cursor-pointer bg-white hover:bg-gray-100 text-gray-900 data-[selected=true]:text-primary-600 text-sm data-[level='1']:font-semibold data-[level='2']:font-medium group/headers peer/headers"
      :data-expandable="level < groupBy.length"
      :data-expanded="expanded"
      :data-selectable="(level === groupBy.length) && selectable"
      :data-selected="selected"
      :data-level="level"
      @click="click"
    >
      <template
        v-for="(header, i) of headers"
        :key="i"
      >
        <DatatableCell
          :header="header"
          :element="sums"
          :no-pill="(i > 0) || (level === groupBy.length)"
        >
          <slot
            :name="header.key"
            v-bind="{ element: sums, elements, level, header }"
          />
        </DatatableCell>
      </template>
    </tr>
    <template v-if="level < groupBy.length">
      <DatatableRows
        v-for="(groupElements, key) in groups"
        :key="key"
        class="hidden peer-data-[expanded=true]/headers:contents hover:cursor-pointer"
        role="row"
        :headers="headers"
        :elements="groupElements"
        :group-by="groupBy"
        :level="level + 1"
        :row-label="key.toString()"
        :row-classes="groupBy[level].class"
        :selection="(selection as TableElement | undefined)"
        :selectable="selectable"
        @select="$emit('select', $event)"
      >
        <template
          v-for="header of headers"
          #[header.key]="itemProps: Props & { element: TableElement }"
        >
          <slot
            :name="header.key"
            v-bind="itemProps"
          />
        </template>
        <template #append="itemProps: Props">
          <slot
            name="append"
            v-bind="itemProps"
          />
        </template>
      </DatatableRows>
    </template>
  </tbody>
</template>
<script setup lang="ts" generic="TableElement extends Record<string, any>">
interface Props {
  headers: TableHeader<TableElement>[]
  elements: TableElement[]
  groupBy: TableGroup<TableElement>[]
  level?: number
  rowLabel?: string
  rowClasses?: string
  selection?: TableElement
  selectable?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  level: 0,
  rowLabel: '',
  rowClasses: 'text-sm font-bold',
  selection: undefined,
  selectable: true
})

const emit = defineEmits<{
  select: [value?: TableElement]
}>()

const expanded = ref(props.level === 0)
const groups = useGroups(props)
const sums = useSums(props)
const selected = computed(() => {
  if (props.level === props.groupBy.length) {
    const key = props.groupBy[props.level - 1].key
    if (sums.value[key] === props.selection?.[key]) {
      return true
    }
  }
  return false
})

function click() {
  if (props.level < props.groupBy.length) {
    expanded.value = !expanded.value
  } else if (props.selectable) {
    const element = sums.value
    emit('select', element)
  }
}

</script>
