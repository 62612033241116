interface Props<TableElement extends Record<string, any>> {
  groupBy: TableGroup<TableElement>[]
  level: number
  headers: TableHeader<TableElement>[]
  elements: TableElement[]
  rowLabel?: string
}
export function useSums<TableElement extends Record<string, any>>(props: Props<TableElement>) {
  return computed(() => {
    const { groupBy, level, headers, elements, rowLabel } = props
    if (level === groupBy.length) {
      return elements[0]
    } else {
      const groupSums: Record<string, any> = {}
      const groupingKeys = groupBy.map(({ key }) => key)

      for (const { key, formula } of headers) {
        let sum
        const groupingIndex = groupingKeys.indexOf(key)
        if (groupingIndex >= 0) {
          sum = rowLabel
          if (groupingIndex < level - 1) {
            sum = elements[0][key]
          }
        } else if (!formula) {
          sum = elements.reduce((total, element) => total + (element[key] || 0), 0)
        } else {
          sum = formula(elements)
        }
        groupSums[key] = sum
      }
      return groupSums as TableElement
    }
  })
}

export function useGroups<TableElement extends Record<string, any>>(props: Props<TableElement>) {
  return computed(() => {
    const key = props.groupBy[props.level]?.key
    const groups: Record<string, TableElement[]> = {}
    for (const element of props.elements) {
      const keyValue = element[key]
      groups[keyValue] = groups[keyValue] || []
      groups[keyValue].push(element)
    }
    return groups
  })
}

export interface TableHeader<TableElement extends Record<string, any>> {
  key: string
  text: string
  format?: 'text' | 'longdate' | 'integer' | 'amount' | 'accounting2' | 'accounting' | 'multiple'
  align?: 'center' | 'left' | 'right'
  space?: boolean
  searchable?: boolean
  formula?: (elements: TableElement[]) => number
  class?: string
  main?: boolean
}

export interface TableGroup<TableElement extends Record<string, any>> {
  key: keyof TableElement
  class?: string
}